














































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { UserModel } from '@/core/models/UserModel'
import { namespace } from 'vuex-class'
const UserStore = namespace('UserStore')

import DepositInvoice from '@/modules/components/DepositInvoice.vue'
import Content from '@/layouts/components/Content.vue'
import BaseTitle from '@/components/base/BaseTitle.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import TopBanner from "@/components/banners/TopBanner.vue"

const ProfileEditForm = () => import('./components/ProfileEditForm.vue')
const ProfileDetails = () => import('./components/ProfileDetails.vue')
const AddTariffPlan = () => import('./components/AddTariffPlan.vue')

@Component({
  components: {
    DepositInvoice,
    Content,
    BaseTitle,
    BaseButton,
    BaseIcon,
    ProfileEditForm,
    ProfileDetails,
    AddTariffPlan,
    TopBanner
  },
  metaInfo: {
    title: 'Профиль',
  },
  beforeRouteLeave(to, from, next) {
    const currentUser = (this as ProfilePage).currentUser
    if (
      to.meta?.layout !== 'auth' &&
      currentUser?.billing_plan?.current === null &&
      currentUser?.billing_plan?.next === null
    ) {
      ;(this as ProfilePage).visibleAddTariffPlan = true
    } else {
      next()
    }
  },
})
export default class ProfilePage extends Vue {
  visibleEditForm = false
  visibleAddTariffPlan = false
  showDepositInvoice = false
  userModel: UserModel = new UserModel()
  current = false
  amount = 0

  @Watch('visibleEditForm')
  onVisibleEditFormChanged(val: boolean): void {
    if (val) {
      this.userModel = JSON.parse(JSON.stringify(this.currentUser))
    }
  }

  @UserStore.Getter
  public currentUser!: UserModel

  @UserStore.Getter
  public isAuth!: boolean

  @UserStore.Action
  private getUser!: () => Promise<UserModel>

  created(): void {
    this.visibleAddTariffPlan =
      this.isAuth &&
      this.currentUser?.billing_plan?.current === null &&
      this.currentUser?.billing_plan?.next === null
  }

  closeAddTariffPlan(): void {
    this.visibleAddTariffPlan = false
    this.getUser()
  }

  invoice(sum: number): void {
    this.visibleAddTariffPlan = false
    this.amount = sum
    this.showDepositInvoice = true
  }
}
